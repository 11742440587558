import { PageProps } from 'gatsby';
import { PaiementData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import paiement from '~/params/paiement.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PagePaiementsUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { espaceId, id },
  } = props;

  return (
    <TemplateEspace
      collectionName="paiements"
      docId={id}
      espace={espace}
      user={user}
    >
      <CMSView>
        <Form
          displayAdd={false}
          displayRemove={false}
          docId={id}
          itemPathnamePrefix={`/espaces/${espaceId}/gestion/paiements/`}
          model={
            new PaiementData({
              espaceId,
              params: paiement,
            })
          }
          name="item"
          type="update"
          {...props}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PagePaiementsUpdate);
